@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Spectral:400,500,700&display=swap');

$font-roboto: 'Roboto Condensed', sans-serif;
$font-spectral: 'Spectral', serif;

$red_text: #d50000;

$trans: all .25s ease;

html {
  background: #0B0B0D;
}

body {
  box-shadow: 0 0 5px #000;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  background: #020001;
}

a {
  outline: none;
}

.red_text {
  color: #d50000;
}

.red_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-roboto;
  font-size: 20px;
  font-weight: 700;
  color: #fff !important;
  text-decoration: none !important;
  background: linear-gradient(to right, rgba(167,39,38,1) 0%, rgba(224,63,63,1) 50%, rgba(167,38,38,1) 100%);
  box-shadow: 0 3px 0 rgba(100,15,13,1),  0 3px 15px rgba(220,67,68,0.7);
  border-radius: 3px;
  transition: $trans;

  &:hover {
    background: linear-gradient(to right, rgba(206,39,37,1) 0%, rgba(239,60,54,1) 50%, rgba(206,39,37,1) 100%);
    box-shadow: 0 3px 0 rgba(100,15,13,1),  0 6px 20px rgba(220,67,68,0.7);
  }
}

header.desktop_header_active {
  display: flex;
}

//HEADER

.mobile_header {
  display: none;
}

header {
  z-index: 100;
  transition: $trans;

  &.scroll {
    box-shadow: 0 15px 40px 0 rgba(47,99,159,0.15);
  }
}

.top_header {
  position: relative;
  z-index: 1;
  font-family: $font-roboto;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background: url("../img/top_header_bg.jpg") no-repeat center;
  background-size: cover;
  box-shadow: 0 5px 15px rgba(0,0,0,0.5);

  a {
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    transition: $trans;

    &:hover {
      color: $red_text;
    }
  }

  .col_left {
    display: flex;
    align-items: center;
    height: 70px;
  }

  .col_right {
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

a.header_vk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-left: 30px;
  box-shadow: inset 0 2px 4px rgba(6,3,0,0.7);
  background: #160608;
  border-radius: 3px;

  &:hover {
    background: #5181B8;
    color: #fff;
  }

  i {
    font-size: 17px;
  }
}

.bottom_header {
  position: relative;
  padding-top: 6px;
  padding-bottom: 15px;
  z-index: 0;
  background: url("../img/bottom_header_bg.jpg") no-repeat center;

  .line_header {
    width: 100%;
    position: absolute;
    bottom: -5px;
  }

  .row {
    align-items: center;
  }

  .col_left {

    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
    }

    li {
      margin-right: 34px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      font-family: $font-roboto;
      font-weight: 700;
      color: #fff;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: $red_text;
      }
    }
  }
  
  .col_center {
    text-align: center;
  }

  .red_btn {
    height: 62px;
    width: 260px;
    margin-left: auto;
  }
}

//MAIN SCREEN

.main_screen {
  padding-top: 128px;
  padding-bottom: 13px;
  background: url("../img/main_screen_bg.jpg") no-repeat center;
  background-size: cover;
  text-align: center;

  h1 {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    font-family: $font-spectral;
    font-weight: 800;
    font-size: 52px;
    line-height: 70px;
    color: #fff;
    text-transform: uppercase;
  }

  h2 {
    margin-bottom: 43px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  h4 {
    position: relative;
    max-width: 722px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 46px;
    padding-left: 170px;
    font-family: $font-roboto;
    font-weight: 700;
    font-size: 24px;
    color: $red_text;
    text-align: left;

    span {
      position: absolute;
      left: 0;
      text-transform: uppercase;
      color: #fff;
    }
  }

  P {
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }
}

.anchors_games_csgo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 330px;

  img {
    border-radius: 5px;
    transition: $trans;

    &:hover {
    transform: scale(1.19);
    }
  }

  a:first-of-type {
    position: relative;
    margin-top: -20px;

    &:after {
      content: url("../img/main_screen_line.png");
      position: absolute;
      top: 165px;
      left: 170px;
    }
  }
}

//TRAINING

.training {
  padding-top: 42px;
  background-color: #020001;
  text-align: center;

  h2 {
    margin-bottom: 43px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }
}

.training_row1 {
  display: none;
}

.training_row2 {
  position: relative;
  display: flex;
  justify-content: space-between;
}

//VIDEO 1

.video1 {
  height: 1020px;
  position: relative;
  padding-top: 128px;
  text-align: center;
  background: url("../img/video1_bg1.jpg") no-repeat center;
  background-size: cover;

  h2 {
    margin-bottom: 147px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }
}

.video1_bg_left {
  position: absolute;
  top: 153px;
  left: 0;
}

.video1_bg_right {
  position: absolute;
  top: 125px;
  right: 0;
}

.video1_video {
  max-width: 840px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background: #000000;
  box-shadow: 0 10px 30px rgba(2,0,1,0.6);

  iframe {
    height: 100%;
    width: 100%;
  }
}

//GALLERY

.gallery {
  position: relative;
  text-align: center;
  background: #020001;

  h2 {
    margin-bottom: 38px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 53px;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }

  img {
    margin-bottom: 40px;
  }
}

.gallery_shadow {
  position: absolute;
  height: 250px;
  left: 0;
  z-index: 10;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.95) 30%, rgba(0,0,0,0.8) 67%, rgba(0,0,0,0) 100%);
}

//TOP LEADER

.top_leader {
  padding-top: 86px;
  padding-bottom: 245px;
  background: url("../img/top_leader_bg.jpg") no-repeat center;
  background-size: cover;
  text-align: center;

  h2 {
    margin-bottom: 38px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -38px;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }
}

.top_leader_slider {
  position: relative;
  width: 410px;
  height: 409px;
  margin-left: auto;
  margin-right: auto;
  background: url("../img/top_leader_slider_bg.png") no-repeat center;

  .slick-slide {
    display: flex;
    padding-top: 76px;
    padding-left: 74px;
    align-items: center;
    height: 409px;
  }

  .prev_arrow {
    position: absolute;
    height: 24px;
    width: 34px;
    cursor: pointer;
    z-index: 10;
    bottom: -56px;
    left: 59px;
  }

  .next_arrow {
    position: absolute;
    height: 24px;
    width: 34px;
    cursor: pointer;
    z-index: 10;
    transform: rotate(180deg);
    bottom: -60px;
    right: 38px;
  }

  .slick-dots {
    bottom: -59px;
    padding-left: 12px;
  }

  .slick-dots li {
    margin-left: 0;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .slick-dots li button {
    height: 14px;
    width: 14px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $red_text;
    border-radius: 50%;
    background-color: transparent;

    &:before {
      display: none;
    }
  }

  .slick-dots li.slick-active button {
    background-color: $red_text;
  }
}

//SPORT

.sport {
  padding-bottom: 140px;
  background: url("../img/sport_bg.jpg") no-repeat center;
  background-size: cover;
  text-align: center;

  &>.container {
    position: relative;
  }

  h2 {
    margin-bottom: 38px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 52px;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }
}

.video2_video {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  background: #000000;
  box-shadow: 0 10px 30px rgba(2,0,1,0.6);

  iframe {
    height: 100%;
    width: 100%;
  }
}

.sport_left_img {
  position: absolute;
  left: -397px;
  top: 100px;
  z-index: 0;
}

.sport_right_img {
  position: absolute;
  top: -37px;
  right: -152px;
  z-index: 10;
}

//OUR COMPUTERS

.our_computers {
  text-align: center;

  &>.container {
    padding-top: 47px;
    background: url("../img/our_computers_bg.jpg") no-repeat center;
  }

  h2 {
    margin-bottom: 43px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  ul {
    margin-bottom: 36px;
    padding-left: 0;
    list-style: none;
  }

  li {
    margin-bottom: 30px;
  }

  h5 {
    font-family: $font-roboto;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }

  p {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
  }
}

//REVIEWS

.reviews {
  text-align: center;

  &>.container {
    padding-top: 43px;
    padding-bottom: 65px;
    background: url("../img/review_bg.jpg") no-repeat center;
  }

  h2 {
    margin-bottom: 43px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #fff;

    span:nth-of-type(1) {
      display: inline-block;
      font-size: 33px;
      line-height: 25px;
    }

    span:nth-of-type(2) {
      display: inline-block;
      font-size: 32px;
      line-height: 16px;
    }
  }
}

.reviews_slider {
  margin-top: 44px;
  margin-left: auto;
  margin-right: auto;

  .prev_arrow {
    position: absolute;
    height: 24px;
    width: 34px;
    cursor: pointer;
    z-index: 10;
    bottom: 184px;
    left: 350px;
  }

  .next_arrow {
    position: absolute;
    height: 24px;
    width: 34px;
    cursor: pointer;
    z-index: 10;
    transform: rotate(180deg);
    bottom: 180px;
    right: 350px;
  }

  .slick-dots {
    bottom: -59px;
    padding-left: 12px;
  }

  .slick-dots li {
    margin-left: 0;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .slick-dots li button {
    height: 14px;
    width: 14px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid $red_text;
    border-radius: 50%;
    background-color: transparent;

    &:before {
      display: none;
    }
  }

  .slick-dots li.slick-active button {
    background-color: $red_text;
  }
}

.review_slide {
  text-align: center;

  .img_wrap {
    position: relative;
    margin-top: 20px;
    max-width: 192px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 43px;

    a {
      position: absolute;
      bottom: -21px;
      left: 68px;
    }
  }

  h5 {
    margin-bottom: 13px;
    font-family: $font-roboto;
    font-size: 16px;
    font-weight: 700;
    color: $red_text;
  }

  p {
    font-size: 16px;
    font-family: $font-roboto;
  }
}

//ACCESSORIES

.accessories {
  padding-top: 100px;
  padding-bottom: 100px;
  background: url("../img/accessories_bg.jpg") no-repeat center;
  background-size: cover;
  text-align: center;

  &>.container {
    position: relative;
  }

  .accessories_phonix {
    position: absolute;
    top: -244px;
    right: 71px;
  }

  h2 {
    margin-bottom: 38px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22px;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }

  .col_left {
    background: url("../img/bg_shirt_1.png") no-repeat center;
    background-size: cover;
  }

  .col_right {
    background: url("../img/bg_shirt_2.png") no-repeat center;
    background-size: cover;
  }
}

.accessories_store {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
  color: $red_text;

  a {
    color: #fff;
    text-decoration: none !important;
    border-bottom: 1px solid #fff;
    transition: $trans;

    &:hover {
      border-bottom: 1px solid transparent;
      color: $red_text;
    }
  }
}

.accessories_zoom {
  display: inline-block;
  position: relative;
  max-width: 418px;
  max-height: 490px;
  margin-left: auto;
  margin-right: auto;
  transition: $trans;

  &:hover {

    .img_zoom {
      visibility: visible;
    }
  }

  .img_zoom {
    visibility: hidden;
    position: absolute;
    top: 50%;
    margin-top: -46px;
    left: 50%;
    margin-left: -46px;
    transition: $trans;
  }
}

.mfp-figure:after {
  background: none;
  box-shadow: none;
}

//ABOUT

.about {
  text-align: center;

  &>.container {
    background: url("../img/about_bg.jpg") no-repeat center;
    background-size: cover;
  }

  h2 {
    margin-bottom: 58px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  .one_quote {
    margin-bottom: 60px;
  }
}

.about_item {
  margin-bottom: 96px;

  p {
    font-family: $font-roboto;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
  }
}

.about_img_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  margin-bottom: 31px;
}

//RESERVATION

.reservation {
  padding-bottom: 90px;
  background: url("../img/reservation_bg.jpg") no-repeat center;
  background-size: cover;
  text-align: center;

  &>.container {
    position: relative;
  }

  .reservation_left_img {
    position: absolute;
    top: 67px;
    left: -158px;
  }

  .reservation_right_img {
    position: absolute;
    top: 98px;
    right: -75px;
  }

  .line_footer {
    margin-bottom: 117px;
  }

  h2 {
    margin-bottom: 43px;
    font-family: $font-spectral;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
    font-family: $font-roboto;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }

  .red_btn {
    max-width: 306px;
    height: 75px;
    margin-bottom: 41px;
    margin-left: auto;
    margin-right: auto;
  }

  .vk_anchor {
    font-family: $font-spectral;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-decoration: none !important;
    border-bottom: 1px solid rgba(255,255,255,.4);
    text-transform: uppercase;
    transition: $trans;

    &:hover {
      color: #5181B8;
      border-bottom: 1px solid transparent;
    }
  }

  .grey_text {
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 108px;
    font-family: $font-roboto;
    font-size: 13px;
    line-height: 25px;
    font-weight: 400;
    color: rgba(255,255,255,.4);
  }
}

                                                //RESERVATION PAGE
//CONTAINER

.content {
  padding-top: 91px;
  padding-bottom: 74px;
  background: url("../img/content_top_bg.jpg") no-repeat top center;
  background-size: contain;

  h1 {
    margin-bottom: 43px;
    font-family: $font-spectral;
    font-size: 52px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
  }

  h3 {
    margin-bottom: 64px;
    font-family: $font-roboto;
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }

  .main_image {
    margin-bottom: 67px;
  }
}

.reservation_main_info {

  h5 {
    max-width: 410px;
    margin-bottom: 20px;
    font-family: $font-roboto;
    font-size: 18px;
    line-height: 30px;
    font-weight: 900;
    color: #fff;
  }

  p {
    font-family: $font-roboto;
    line-height: 30px;
    font-weight: 400;
    color: #fff;
  }

  .info {
    position: relative;
    margin-top: 45px;
    padding-left: 40px;

    &:before {
      content: url("../img/icons/info_ico.png");
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .grey_line {
    height: 1px;
    width: 100%;
    margin-top: 55px;
    background: rgba(255,255,255,.2);
  }
}

.reservation_included {
  padding-top: 48px;
  
  h3 {
    margin-bottom: 33px;
    text-align: left;
  }

  p {
    font-family: $font-roboto;
    line-height: 30px;
    font-weight: 400;
    color: #fff;
  }

  ul {
    margin-top: 38px;
    margin-bottom: 0;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 15px;
      font-family: $font-roboto;
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      color: #fff;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        top: 8px;
        left: -40px;
        background-color: $red_text;
      }
    }
  }
}

.schedule {
  margin-bottom: 41px;
  padding-top: 55px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 60px;
  background: #1C1A1B;

  h4 {
    margin-bottom: 18px;
    font-family: $font-roboto;
    font-size: 24px;
    font-weight: 700;
    color: #fff;

  }

  .table_row {
    display: flex;
    font-family: $font-roboto;
    font-size: 16px;
    font-weight: 400;
    color: #fff;

    &.sale_closet {
      color: rgba(255,255,255,.4);
    }

    .table_col {
      display: flex;
      align-items: center;
      height: 70px;
      border-bottom: 1px solid rgba(255,255,255,.1);

      &:nth-of-type(1) {
        font-weight: 700;
        width: 20%;
        text-transform: uppercase;
      }

      &:nth-of-type(2) {
        width: 25%;
        justify-content: center;
      }

      &:nth-of-type(3) {
        font-weight: 700;
        width: 25%;
        justify-content: center;
      }

      &:nth-of-type(4) {
        width: 30%;
      }
    }
  }

  a {
    font-family: $font-roboto;
    font-size: 16px;
    font-weight: 700;
    color: $red_text;
    text-decoration: none !important;
    border-bottom: 1px solid $red_text;
    transition: $trans;

    &:hover {
      color: #fff;
      border-bottom: 1px solid transparent;
    }
  }
}

.footnotes {

  ul {
    margin-bottom: 0;
    padding-left: 28px;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 15px;
      font-family: $font-roboto;
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;
      color: #8d8d8d;

      a {
        font-weight: 700;
        color: #fff;
        text-decoration: none !important;
        border-bottom: 1px solid #fff;
        transition: $trans;

        &:hover {
          color: $red_text;
          border-bottom: 1px solid transparent;
        }
      }

      &:nth-of-type(1):before {
        content: '*';
        position: absolute;
        top: 2px;
        left: -28px;
        font-size: 14px;
        font-weight: 400;
        color: #8d8d8d;
      }

      &:nth-of-type(2):before {
        content: '**';
        position: absolute;
        top: 2px;
        left: -28px;
        font-size: 14px;
        font-weight: 400;
        color: #8d8d8d;
      }
    }
  }
}

.samples {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
}

a.sample {
  position: relative;
  display: flex;
  align-items: center;
  width: 260px;
  height: 80px;
  padding-left: 85px;
  padding-right: 20px;
  border: 1px solid #1C1A1B;
  font-family: $font-roboto;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  text-decoration: none !important;
  cursor: pointer;
  transition: $trans;

  &:hover {
    border: 1px solid $red_text;
  }

  object {
    position: absolute;
    left: 24px;
    top: 22px;
  }
}


.more_about {
  padding-top: 129px;

  h4 {
    margin-bottom: 72px;
    font-family: $font-spectral;
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }
}

.more_about_anchors {
  display: flex;
  justify-content: space-between;

  img {
    border-radius: 5px;
    transition: $trans;

    &:hover {
      transform: scale(1.19);
    }
  }
}

.reservation_second {

  .grey_text {
    margin-top: 81px;
  }
}

                                                //FAQ PAGE

.faq_page {
  padding-bottom: 116px;

  .main_image {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .more_about {
    padding-top: 104px;

    h4 {
      font-family: $font-spectral;
      font-size: 36px;
      font-weight: 800;
      color: #fff;
      text-transform: uppercase;
    }
  }
}

.faq_accordeon {
  margin-top: -182px;

  &>ol {
    padding-left: 0;
    list-style: none;
    counter-reset: item;

    &>li {
      position: relative;
      border-top: 1px solid rgba(255,255,255,.2);
      border-bottom: 1px solid rgba(255,255,255,.2);

      &:before {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        top: 29px;
        background: $red_text;
        font-family: $font-spectral;
        font-size: 24px;
        font-weight: 700;
        content: counter(item);
        counter-increment: item;
        color: #fff;
      }
      
      &.active {

        .question {

          i {
            transform: rotate(180deg);
            color: rgba(255,255,255,.2);
          }
        }
      }
    }
  }

  .question {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
    font-family: $font-spectral;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: $trans;

    &:hover {
      i {
        color: rgba(255,255,255,.2);
      }
    }

    i {
      color: #fff;
      font-size: 38px;
      margin-left: 20px;
    }
  }

  .answer {
    padding-left: 60px;
    padding-bottom: 36px;
    display: none;

    h5 {
      margin-bottom: 22px;
      font-family: $font-roboto;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #fff;
    }

    h6 {
      margin-bottom: 24px;
      font-family: $font-roboto;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
    }

    ol {
      padding-left: 0;
      margin-bottom: 26px;
      counter-reset: item;
      list-style: none;

      li {
        margin-bottom: 21px;
        font-family: $font-roboto;
        font-weight: 400;
        font-size: 16px;
        color: #fff;

        &:before {
          content: counter(item) ". ";
          counter-increment: item;
          color: $red_text;
        }
      }
    }

    p {
      font-family: $font-roboto;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
    }
  }
}


                                              //ABOUT PAGE
.about_page {
  padding-bottom: 115px;

  h4 {
    margin-bottom: 51px;
    max-width: 752px;
    margin-left: auto;
    margin-right: auto;
    font-family: $font-roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: #fff;

    span {
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  p {
    margin-bottom: 30px;
    font-family: $font-roboto;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #fff;
  }

  .more_about {
    padding-top: 104px;

    h4 {
      max-width: 100%;
      font-family: $font-spectral;
      font-size: 36px;
      font-weight: 800;
      color: #fff;
      text-transform: uppercase;
    }
  }
}

.requisites {
  margin-top: 74px;

  .line_footer {
    margin-bottom: 82px;
  }

  h4 {
    margin-bottom: 38px;
    max-width: 100% !important;
    text-align: left;
  }

  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 26px;
    font-family: $font-roboto;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
}

                              //FORPARENTS PAGE

.forparents {
  padding-bottom: 114px;

  h3 {
    margin-bottom: 43px;
    font-size: 24px;
    line-height: 50px;
  }

  ol {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    counter-reset: item;
  }

  li {
    position: relative;
    margin-bottom: 40px;
    padding-left: 98px;
    font-family: $font-roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #fff;

    &:before {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      top: 10px;
      left: 0;
      background: $red_text;
      font-family: $font-spectral;
      font-size: 24px;
      font-weight: 700;
      content: counter(item);
      counter-increment: item;
      color: #fff;
    }

    a {
      font-weight: 700;
      color: $red_text;
      text-decoration: none;
      border-bottom: 1px solid $red_text;
      transition: $trans;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .more_about h4 {
    font-size: 36px;
  }
}

.ps {
  position: relative;
  height: 434px;
  padding-left: 98px;
  padding-right: 490px;
  padding-top: 143px;
  background: url("../img/ps_bg.png") no-repeat center;

  &:before {
    position: absolute;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $red_text;
    content: 'P.S.';
    font-family: $font-roboto;
    font-weight: 700;
    color: #fff;
    bottom: 104px;
    left: 0;
  }

  h5 {
    font-family: $font-roboto;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
  }

  p {
    margin-bottom: 25px;
    font-family: $font-roboto;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }

  a {
    color: $red_text;
    font-weight: 700;
    text-decoration: none !important;
    border-bottom: 1px solid $red_text;
    transition: $trans;

    &:hover {
      color: #fff;
      border-bottom: 1px solid transparent;
    }
  }
}